.app-icon {
  margin-left: auto;
  margin-right: auto;
  width: 100px;
  height: 100px;
  padding: 20px 22.68px;
  border-radius: 22.5%;

  background-image: linear-gradient(to bottom, rgba(18,111,235,1),rgba(114,3,225,1));
}
