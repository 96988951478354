$primary: rgb(18, 111, 235);
$border-color: #E3EBF6;

$active-color: $primary;

$nav-row-item-border-color-active: $primary;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "~@bootstrap-extended-style/bootstrap-extended-style/index";

@import "~@fortawesome/fontawesome-pro/scss/fontawesome";
@import "~@fortawesome/fontawesome-pro/scss/brands";
@import "~@fortawesome/fontawesome-pro/scss/light";
@import "~@fortawesome/fontawesome-pro/scss/regular";
@import "~@fortawesome/fontawesome-pro/scss/solid";
@import "~@fortawesome/fontawesome-pro/scss/duotone";

@import "~react-loading-skeleton/dist/skeleton.css";

@import "~@zandor300/backoffice-framework/lib/scss/backoffice-framework";

@import "live";
@import "logo";

$banner-image: url("../img/sidebar-background.jpg");
$login-background-color1: rgba(18, 111, 235, 0.8);
$login-background-color2: rgba(114, 3, 225, 0.8);
$sidebar-background-color1: rgba(18, 111, 235, 0.9);
$sidebar-background-color2: rgba(114, 3, 225, 0.9);

.login-background {
  background-image: linear-gradient(to bottom right, $login-background-color1, $login-background-color2), $banner-image !important;
}
.sidebar {
  background-image: linear-gradient(to bottom right, $sidebar-background-color1, $sidebar-background-color2), $banner-image !important;
}

.sidebar {
  .nav-link {
    .nav-link-icon {
      margin-right: 0;
    }
    .nav-link-content {
      margin-left: 1rem;
      opacity: 1;
      -webkit-transition: opacity $sidebar-transition-speed ease-in-out;
      -moz-transition: opacity $sidebar-transition-speed ease-in-out;
      -ms-transition: opacity $sidebar-transition-speed ease-in-out;
      -o-transition: opacity $sidebar-transition-speed ease-in-out;
      transition: opacity $sidebar-transition-speed ease-in-out;
    }
  }
  &.collapsed {
    .nav-link {
      text-align: left;
      .nav-link-content {
        display: block;
        opacity: 0;
      }
    }
  }
}

.btn {
  font-weight: 600;
}
