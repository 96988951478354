.livenow {
  width: 30px;
  margin: 0 auto;
  display: inline-block;
}

.livenow > div {
  vertical-align: middle;
  width: 22px;
  height: 22px;
  border-radius: 100%;
  position: absolute;
  margin: 0 auto;
  border: 3px solid rgba(255, 255, 255, 1);

  &:nth-child(1) {
    margin: 2px;
    width: 18px;
    height: 18px;
    background-color: rgba(23, 97, 215, 1);
  }

  &:nth-child(2) {
    background-color: rgba(23, 97, 215, 1);
    border: 3px solid rgba(23, 97, 215, 1);
    opacity: 0.25;
  }

  &:nth-child(3) {
    border: 3px solid rgba(23, 97, 215, 1);
    -webkit-animation: live 3s infinite ease-in-out;
    animation: live 3s infinite ease-in-out;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
}

@-webkit-keyframes live {
  0%, 80%, 100% {
    -webkit-transform: scale(0.5)
  }
  40% {
    -webkit-transform: scale(1.0)
  }
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes live {
  0%, 80%, 100% {
    transform: scale(0.5);
    -webkit-transform: scale(0.5);
  }
  40% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
